/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './line-item-template-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import searchSelect from '../common/controller/search-select-component';
import starDirective from '../common/directives/star/star-directive';
import {Uuid} from "../../components/util/uuid";
import {lineItemModDates, setLineItemDefaults} from "./line-item-commons";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = "line-item-template-actions-controller";

angular.module(MODULE_NAME, [standardActionsModule, searchSelect, starDirective])

  .controller('LineItemTemplateActionsCtrl', function(sectionParam, model, LocalUserPermissions, $q, toastr, adnTimeShifter, adnModalLoading, LocalNetworkProfile, LineItemTemplate, $log, $state, $stateParams, $uibModal, modelComms, readOnly) {
    const ctrl = this;

    const sectionPerms = LocalUserPermissions.getSectionPerms();
    const specificEditableParameter = sectionParam + 'Editable';
    if (sectionParam && Object.hasOwn(sectionPerms, specificEditableParameter)) {
      ctrl.readOnly = !sectionPerms[specificEditableParameter];
    } else {
      ctrl.readOnly = sectionParam && Object.hasOwn(sectionPerms, sectionParam) ? !sectionPerms[sectionParam] : readOnly;
    }
    ctrl.hasLineItemTemplates = LocalNetworkInfo.obtain().getInfo('lineItemTemplatesCount') > 0;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;

    ctrl.loadExternalLineItem = function() {
      const viewType = LocalNetworkProfile.isCalt() ? 2 : 1;
      $state.go("app.line-items.new", {viewType: viewType, full: viewType === 2, orderId: model.id, orderName: model.name, advertiserId: _.get(ctrl.model, ['advertiser', 'id']), advertiserName: _.get(ctrl.model, ['advertiser', 'name'])});
    };

    ctrl.copy = function(copyToLineItem, fromLineItemList) {
      let modalInstance = $uibModal.open({
        template: template,
        windowClass: '',
        size: 'lg',
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modalCtrl.copyToLineItem = copyToLineItem;
          modalCtrl.isNew = true;
          modalCtrl.fromLineItemList = fromLineItemList;

          if (fromLineItemList) {
            LineItemTemplate.query(function(data) {
              modalCtrl.allTemplates = data.results;
            });
          }

          modelComms.addSubscriber = modelComms.addSubscriber || _.noop;

          function assignInfo(data) {
            modalCtrl.lineItem = data;
            modalCtrl.lineItem.name = data.name + " - derived from line item template";
            setLineItemDefaults(LocalNetworkProfile, modalCtrl, modalCtrl.lineItem);
            if (modalCtrl.lineItem.startDate) {
              modalCtrl.lineItem.modStartDate = adnTimeShifter.timeShift(modalCtrl.lineItem.startDate);
            }
            if (modalCtrl.lineItem.endDate) {
              modalCtrl.lineItem.modEndDate = adnTimeShifter.timeShift(modalCtrl.lineItem.endDate);
            }
          }

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              assignInfo(data);
            }
          });

          modalCtrl.templateChanged = function() {
            modalCtrl.updating = true;
            assignInfo(modalCtrl.lineItemTemplate);
            modalCtrl.updating = false;
          };

          modalCtrl.destination = true;
          modalCtrl.copy = {
            targeting: true,
            creatives: true
          };

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.copyCampaign = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();
            const newId = Uuid.generate();
            const method = copyToLineItem ? 'copyAsLineItem' : 'copyLineItemTemplate';
            lineItemModDates(adnTimeShifter, modalCtrl.lineItem, modalCtrl.lineItem.modStartDate, modalCtrl.lineItem.modEndDate);
            $q.all(LineItemTemplate[method](modalCtrl.lineItem, newId, modalCtrl.copy.targeting)).then(function() {
              modalCtrl.disabled = false;
              modalInstance.close({destination: modalCtrl.destination, newId: newId, copyToLineItem: copyToLineItem});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        adnModalLoading.removeModalLoading();
        const url = copyToLineItem ? 'app.line-items.line-item' : 'app.line-item-templates.line-item-template';
        const text = copyToLineItem ? 'line item' : 'line item template';
        if (result.destination) {
          $state.go(url, {id: result.newId}, {inherit: false});
        }
        if (result.errors) {
          $log.warn(result.errors);
          toastr.warning("This " + text + " could not be copied.", "Copy of " + text.toUpperCase() + " Failed");
        } else if (!result.destination) {
          toastr.success("A copy of this " + text + " was successfully created.", text.toUpperCase() + " Copied");
        }
      }, function() {
        // dismissal handler to avoid unhandled exceptions -- don't blame me, blame uibModal
      });
    };
  });

export default MODULE_NAME;