import {NgModule} from '@angular/core';
import {ReachEstimateResource} from "./resource/reach-estimate";
import {AuditResource} from "./resource/audit";
import {VisitorProfileResource} from "./resource/visitor-profile";
import {DataViewResource} from "./resource/data-view";
import {DevicesResource} from "./resource/devices";
import {VersionHistoryResource} from "./resource/version-history";
import {StatsResource} from "./resource/stats-resources/stats-resource";
import {CurrenciesResource} from "./resource/currencies";
import {TimezonesResource} from "./resource/timezones";
import {ReportTranslationDefaultsResource} from "./resource/report-translation-defaults";
import {BurnRatesResource} from "./resource/stats-resources/burn-rates-resource";
import {LocationResource} from "./resource/location";
import {CategorySearchResource} from "./resource/categories";
import {SearchResource} from "./resource/search";
import {TierResource} from "./resource/tier";
import {KeywordProfileSearchResource} from "./resource/keyword-profile-search";
import {TargetingEmbeddingSearchResource} from "./resource/targeting-embedding-search";
import {ArticleSearchResource} from "./resource/article-search";
import {KeyValueSearchResource} from "./resource/key-value-search";
import {ExternalSearchResource} from "./resource/external-search";
import {DiagRequestResource} from "./resource/diag-request";
import {LivePreviewResource} from "./resource/live-preview";
import {AuthenticatedUserResource} from "./resource/authenticated-user";
import {DefaultEmailTranslationsResource} from "./resource/default-email-translations";
import { DefaultInvoiceTranslationsResource } from "./resource/default-invoice-translations";
import {CrawlerResource} from "./resource/crawler";
import {TrafficHistogramResource} from "./resource/traffic-histogram";
import {KeywordSearchResource} from "./resource/keyword-search";
import {RecentArticlesSearchResource} from "./resource/recent-articles";

@NgModule({
  providers: [
    ReachEstimateResource,
    AuditResource,
    VisitorProfileResource,
    DataViewResource,
    DevicesResource,
    VersionHistoryResource,
    StatsResource,
    TargetingEmbeddingSearchResource,
    RecentArticlesSearchResource,
    CurrenciesResource,
    TimezonesResource,
    ReportTranslationDefaultsResource,
    BurnRatesResource,
    LocationResource,
    CategorySearchResource,
    SearchResource,
    TierResource,
    AuthenticatedUserResource,
    KeywordSearchResource,
    KeywordProfileSearchResource,
    ArticleSearchResource,
    KeyValueSearchResource,
    ExternalSearchResource,
    DiagRequestResource,
    LivePreviewResource,
    DefaultEmailTranslationsResource,
    DefaultInvoiceTranslationsResource,
    CrawlerResource,
    TrafficHistogramResource
  ]
})
export class ResourcesModule { }