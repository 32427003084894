import {MediaTypeHelper} from "../../../../components/util/media-type-helper";
import {OBJECT_STATE} from "../../../../components/api/resources/resources-constants";

import audioTemplate from "./audio.hbs";
import videoTemplate from "./video.hbs";
import nativeTemplate from "./native.hbs";
import native2Template from "./native2.hbs";
import nativeBasicTemplate from "./native-basic.hbs";
import overlayTemplate from "./overlay.hbs";
import interstitialTemplate from "./interstitial.hbs";
import imageResponsiveTemplate from "./image-responsive.hbs";
import imageStreamingTemplate from "./image-streaming.hbs";
import parallaxTemplate from "./parallax.hbs";
import responsiveScaledTemplate from "./responsive-scaled.hbs";
import imageSelectionTemplate from "./image-selection.hbs";
import imageRolloverTemplate from "./image-rollover.hbs";
import vastExternalTemplate from "./vast-external-xml.hbs";
import breakoutTemplate from "./break-out.hbs";
import googlePassbackTemplate from "./google-passback.hbs";

const videoBasic = (function() {
  const ac = {
    type: 'ASSET',
    tag: 'video',
    name: 'Video - Basic',
    mediaType: 'video',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MediaTypeHelper.getVideoMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const img = {
    type: 'ASSET',
    tag: 'poster',
    name: 'Poster',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'OPTIONAL',
    assets: []
  };
  return {
    id: 'video-basic',
    name: 'Video - Basic',
    kv: 'videoBasic',
    notes: 'There is no click destination for this video.',
    layoutType: 'STANDARD',
    description: 'Plays a video directly using the video tag.',
    renderTemplate: videoTemplate,
    layoutComponents: [ac, img]
  };
})();

const audioBasic = (function() {
  const ac = {
    type: 'ASSET',
    tag: 'audio',
    name: 'Audio - Basic',
    mediaType: 'audio',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MediaTypeHelper.getAudioMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  return {
    id: 'audio-basic',
    name: 'Audio - Basic',
    kv: 'audioBasic',
    notes: 'There is no click destination for this audio.',
    layoutType: 'STANDARD',
    description: 'Plays audio directly using the audio tag.',
    renderTemplate: audioTemplate,
    layoutComponents: [ac]
  };
})();

const breakOut = (function() {
  const img = {
    type: 'ASSET',
    tag: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'breakout',
    name: 'Break-Out',
    kv: 'breakout',
    layoutType: 'STANDARD',
    description: 'Displays an image in the bottom-right corner of the page while obscuring the view of the content underneath.',
    renderTemplate: breakoutTemplate,
    layoutComponents: [img, uc]
  };
})();

const overlay = (function() {
  const img = {
    type: 'ASSET',
    tag: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'overlay',
    name: 'Overlay',
    kv: 'overlay',
    notes: 'If you can read this, the ad hasn\'t loaded yet or it\'s disappeared once you hit the cross on the image.',
    layoutType: 'STANDARD',
    description: 'Displays an image over the top of the page while obscuring all view of the content underneath.',
    renderTemplate: overlayTemplate,
    layoutComponents: [img, uc]
  };
})();

const native = (function() {
  const img = {
    type: 'ASSET',
    tag: 'image',
    name: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const title = {
    type: 'TEXT',
    tag: 'title',
    name: 'Title',
    required: 'MANDATORY',
    minLength: 0,
    maxLength: 100
  };
  const text = {
    type: 'TEXT',
    tag: 'text',
    name: 'Text',
    minLength: 5,
    maxLength: 100,
    required: 'MANDATORY'
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'native',
    name: 'Native',
    kv: 'native',
    layoutType: 'STANDARD',
    description: 'Displays a native ad with text and image. Adjusts the image to ensure all the content fits within the creative dimensions.',
    renderTemplate: nativeTemplate,
    layoutComponents: [img, title, text, uc]
  };
})();

const native2 = (function() {
  const img = {
    type: 'ASSET',
    tag: 'image',
    name: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const title = {
    type: 'TEXT',
    tag: 'title',
    name: 'Title',
    required: 'MANDATORY',
    minLength: 0,
    maxLength: 100
  };
  const text = {
    type: 'TEXT',
    tag: 'text',
    name: 'Text',
    minLength: 5,
    maxLength: 100,
    required: 'MANDATORY'
  };
  const linkText = {
    type: 'TEXT',
    tag: 'linkText',
    name: 'Link Text',
    minLength: 0,
    maxLength: 100,
    required: 'OPTIONAL'
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Link URL',
    required: 'MANDATORY'
  };
  return {
    id: 'native2',
    name: 'Native Horizontal',
    kv: 'native2',
    layoutType: 'STANDARD',
    description: 'Displays a native ad with image to the left and text on the right. Adjusts the image to ensure all the content fits within the creative dimensions.',
    renderTemplate: native2Template,
    layoutComponents: [img, title, text, linkText, uc]
  };
})();

const nativeBasic = (function() {
  const img = {
    type: 'ASSET',
    tag: 'image',
    name: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const title = {
    type: 'TEXT',
    tag: 'title',
    name: 'Title',
    required: 'MANDATORY',
    minLength: 0,
    maxLength: 100
  };
  const text = {
    type: 'TEXT',
    tag: 'text',
    name: 'Text',
    minLength: 5,
    maxLength: 100,
    required: 'MANDATORY'
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'native-basic',
    name: 'Native Basic',
    kv: 'nativeBasic',
    layoutType: 'STANDARD',
    description: 'Displays a basic native ad with image and text.',
    renderTemplate: nativeBasicTemplate,
    layoutComponents: [img, title, text, uc]
  };
})();

const interstitial = (function() {
  const img = {
    type: 'ASSET',
    tag: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'interstitial',
    name: 'Interstitial',
    kv: 'interstitial',
    notes: 'If you can read this, the ad hasn\'t loaded yet or it\'s disappeared once you hit the cross on the image.',
    layoutType: 'STANDARD',
    description: 'Displays an image over the top of the page while blocking out all view of the content underneath.',
    renderTemplate: interstitialTemplate,
    layoutComponents: [img, uc]
  };
})();

const imageResponsive = (function() {
  const img = {
    type: 'ASSET',
    tag: 'image',
    name: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'image-responsive',
    name: 'Image - Responsive',
    notes: 'Adjust the width of your browser to see the image adjust its size to match the available space.',
    kv: 'responsive',
    layoutType: 'STANDARD',
    description: 'Single image that scales to the size of its container.',
    renderTemplate: imageResponsiveTemplate,
    layoutComponents: [img, uc]
  };
})();

const imageStreaming = (function() {
  const img = {
    type: 'ASSET',
    tag: 'Image',
    name: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getStreamingImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'image-streaming',
    name: 'Image - Streaming',
    notes: '',
    kv: 'imageStreaming',
    layoutType: 'STANDARD',
    description: 'Loads with a low-resolution version of the image, whilst the full-resolution version is downloaded in the background.',
    renderTemplate: imageStreamingTemplate,
    layoutComponents: [img, uc]
  };
})();

const imageRollover = (function() {
  const img = {
    type: 'ASSET',
    tag: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'image-rollover',
    name: 'Image Rollover',
    layoutType: 'STANDARD',
    kv: 'imageRollover',
    notes: 'Hover your mouse over the image to see it expand.',
    description: 'Image expands on mouseover, rolling over the top of content around it.',
    renderTemplate: imageRolloverTemplate,
    layoutComponents: [img, uc]
  };
})();

const parallax = (function() {
  const img = {
    type: 'ASSET',
    tag: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'parallax-layout-example',
    name: 'Parallax',
    kv: 'parallax',
    instructions: "<h2 style=\"color: red;\">IMPORTANT</h2><p>To make parallax ads work on your page, you will have to make sure that you have set up your page correctly. Information <a href=\"https://docs.adnuntius.com/other-useful-information/parallax-for-ad-server-clients\" target=\"\"><b>here</b></a>. You will also have to make sure that the content is centered in your content because the edges might get cropped due to different screen sizes.</p><p><br/></p>",
    layoutType: 'STANDARD',
    description: 'Delivers an image presented with a parallax effect in response to page scroll',
    renderTemplate: parallaxTemplate,
    layoutComponents: [img, uc]
  };
})();

const responsiveScaled = (function() {
  const img = {
    type: 'ASSET',
    tag: 'largeImage',
    name: 'Large Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const mediumImage = {
    type: 'ASSET',
    tag: 'mediumImage',
    name: 'Medium Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'OPTIONAL',
    assets: []
  };
  const smallImage = {
    type: 'ASSET',
    tag: 'smallImage',
    name: 'Small Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'OPTIONAL',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'responsive-scaled',
    name: 'Responsive Scaled (1 to 3 images)',
    layoutType: 'STANDARD',
    kv: 'responsiveScaled',
    notes: 'Resize your browser window to see a different scaled image show as part of the ad. The largest image never grows wider than its default size.',
    description: 'Shows one of up to three images depending on container size. If the container is resized, the image displayed will also be updated accordingly. Images shall also scale themselves to fit the space available.',
    renderTemplate: responsiveScaledTemplate,
    layoutComponents: [img, mediumImage, smallImage, uc]
  };
})();

const imageSelection = (function() {
  const img = {
    type: 'ASSET',
    tag: 'largeImage',
    name: 'Large Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const mediumImage = {
    type: 'ASSET',
    tag: 'mediumImage',
    name: 'Medium Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'OPTIONAL',
    assets: []
  };
  const smallImage = {
    type: 'ASSET',
    tag: 'smallImage',
    name: 'Small Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'OPTIONAL',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'image-selection',
    name: 'Image Selection (1 to 3 images)',
    layoutType: 'STANDARD',
    kv: 'imageSelection',
    description: 'Shows one of up to three images depending on container size. No scaling takes place.',
    notes: 'Refresh your browser at different sizes to see a different image show as part of the ad.',
    renderTemplate: imageSelectionTemplate,
    layoutComponents: [img, mediumImage, smallImage, uc]
  };
})();

const vastThirdParty = (function() {
  const uc = {
    type: 'URL',
    tag: 'vastUrl',
    name: 'VAST URL',
    required: 'MANDATORY'
  };
  return {
    id: 'vast-third-party',
    name: 'Third Party VAST Ad Tag',
    kv: 'vastExternal',
    layoutType: 'VAST',
    notes: 'A pre-roll VAST advertisement is shown along with the clip.',
    description: 'Delivers a VAST advertisement from a third-party VAST Ad Tag URL. Supports VAST versions 2.0, 3.0 and 4.0',
    renderTemplate: vastExternalTemplate,
    vastUrl: 'https://delivery.adnuntius.com/i?tzo=-660&auId=000000000007eb8e&tt=vast2&examples=vastExternal',
    layoutComponents: [uc]
  };
})();

const googlePassback = (function() {
  const slotComponent = {
    type: "TEXT",
    tag: "adslot",
    name: "Ad Slot",
    required: "MANDATORY",
    textType: "TEXT",
    minLength: 0,
    maxLength: 100
  };
  const sizesComponent = {
    type: "TEXT",
    tag: "sizeArray",
    name: "Size Array",
    required: "MANDATORY",
    textType: "TEXT",
    minLength: 0,
    maxLength: 200
  };
  return {
    id: 'google-passback',
    name: 'Google Passback Tag',
    kv: 'googlePassback',
    layoutType: 'STANDARD',
    notes: 'We do not provide an example fetching ads from Google',
    description: 'Renders a passback tag to fetch ads from Google',
    renderTemplate: googlePassbackTemplate,
    layoutComponents: [slotComponent, sizesComponent]
  };
})();

export const LAYOUT_BUNCH = {
  audio: audioBasic,
  video: videoBasic,
  native: native,
  native2: native2,
  nativeBasic: nativeBasic,
  overlay: overlay,
  imageResponsive: imageResponsive,
  imageStreaming: imageStreaming,
  parallax: parallax,
  interstitial: interstitial,
  breakOut: breakOut,
  responsiveScaled: responsiveScaled,
  imageSelection: imageSelection,
  imageRollover: imageRollover,
  vastThirdParty: vastThirdParty,
  googlePassback: googlePassback
};
