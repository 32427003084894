import * as _ from 'lodash';

import { OBJECT_TYPE, ObjectTypeFinder } from "../../../../app/common/constants/object-type";
import {ApiConfig} from "../../api";
import { ResourceHelper } from "../../../util/resource-helper";

export class SearchHelper {
  private checkObjectType: boolean;

  constructor(private $http, private localUserProfile, private endPoint = "search") {
    this.checkObjectType = this.endPoint === 'search';
  }

  updateEndPoint(endPoint: string) {
    this.endPoint = endPoint;
  }

  query(a1, a2?, a3?) {
    let params: any = {};
    switch (arguments.length) {
      case 3:
        params = a3;
      /* falls through */
      case 2:
        if (_.isObject(a2) && !_.isArray(a2)) {
          params = a2;
        } else {
          params.types = a2;
        }
      /* falls through */
      case 1:
      /* falls through */
      default:
        if (_.isObject(a1)) {
          params = a1;
        } else {
          params.q = a1;
        }

        if (_.isArray(params.types)) {
          params.types = params.types.join(';');
        }
    }

    if (!params.q && params.types && !params.blankOrderBy) {
      params.orderBy = "NAME";
    }

    params.q = ResourceHelper.uiSrefUpdater(params.q);
    params.labels = ResourceHelper.uiSrefUpdater(params.labels);

    const newParams = _.cloneDeep(params);
    if (newParams.q && newParams.q.length < 3 && !_.endsWith(newParams, "*")) {
      // with search queries that are one or two letters long, adding a wildcard returns much better results
      newParams.q = newParams.q + "*";
    }
    if (!newParams.objectState) {
      newParams.objectState = "ACTIVE";
    }

    return this.$http.get(ApiConfig.obtain().getVersionedUri(this.endPoint), {
      params: newParams
    }).then((response) => {
      const data = response.data;
      if (!data.searchResults) {
        return data;
      }
      data.searchResults = _.compact(_.map(data.searchResults, (result) => {
        if (!this.checkObjectType) {
          return result;
        }

        if (this.localUserProfile.get().app === 'DATA' && result.type === 'UserSegment') {
          result.type = 'Segment';
          result.objectType = OBJECT_TYPE.Segment;
          return result;
        }

        const objectType = ObjectTypeFinder.findSearchObject(result.type);
        if (objectType) {
          result.objectType = objectType;
          return result;
        }
      }));
      return data;
    });
  }

  post(body, params) {
    return this.$http.post(ApiConfig.obtain().getVersionedUri(this.endPoint), body, {params: params || {}}).then((response) => {
      const data = response.data;
      if (!data.searchResults) {
        return data;
      }
      data.searchResults = _.compact(_.map(data.searchResults, (result) => {
        if (!this.checkObjectType) {
          return result;
        }

        const objectType = ObjectTypeFinder.findSearchObject(result.type);
        if (objectType) {
          result.objectType = objectType;
          return result;
        }
      }));
      return data;
    });
  }
}
