/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './line-item-edit-2.html';
import analysisTemplate from './line-item-template-analysis.html';
import lineItemTemplateActionsTemplate from './line-item-template-actions.html';
import listTemplate from './line-item-template-list.html';

import standardModule from '../common/standard-module';
import lineItemController from './line-item-controller';
import lineItemAnalysis from './line-item-analysis-controller';
import lineItemTemplateActionsController from './line-item-template-actions-controller';
import lineItemReachController from './line-item-reach-controller';
import objectivesViewComponent from './widget/objectives-view-component';
import bidsViewComponent from './widget/bids-view-component';
import lineItemDiagController from './line-item-diagnostics-controller';
import {lineItemBulkAction} from "./line-item-commons";

const MODULE_NAME = 'line-item-template-module';

angular.module(MODULE_NAME, [standardModule, lineItemController, objectivesViewComponent, bidsViewComponent, lineItemAnalysis, lineItemTemplateActionsController, lineItemReachController, lineItemDiagController])
  .config(function($stateProvider, adnRouterHelperProvider) {
    const rh = adnRouterHelperProvider.$get().createRouterHelper('line-item-templates.line-item-template', 'LineItemTemplate',  'app.adv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile) {
          return {
            func: function(ctrl) {
              ctrl.hasObjectSource = LocalNetworkProfile.hasObjectSource();
              ctrl.lineItemFields = ['name', {label: 'tier', param: 'Tier'}, {param: 'objectives', numeric: true}];
            }
          };
        },
        bulk: function() {
          return {
            bulkAction: lineItemBulkAction
          };
        },
        sectionParam: function() {
          return 'lineItems';
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          advertiserFromParam: function() {
          },
          orderFromParam: function() {
          },
          lineItemView: function() {
            return null;
          },
          full: function() {
            return false;
          },
          viewType: function() {
            return 1;
          },
          model: /*@ngInject*/ function($q, $stateParams, LineItemTemplate) {
            if ($stateParams.isNew) {
              return LineItemTemplate.create({
                id: $stateParams.id
              });
            }
            return LineItemTemplate.get({id: $stateParams.id}).$promise;
          }
        },
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'rightPanel@app.line-item-templates.line-item-template': {
            template: analysisTemplate,
            controller: function(model) {
              const ctrl = this;
              ctrl.lineItemTemplate = model;
            },
            controllerAs: 'ctrl'
          },
          'leftPanel@app.line-item-templates.line-item-template': {
            template: editTemplate,
            controller: 'LineItemCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: lineItemTemplateActionsTemplate,
            controller: 'LineItemTemplateActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'LineItemTemplate'));
  });

export default MODULE_NAME;