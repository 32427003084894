/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import _ from "lodash";
import writeXlsxFile from "write-excel-file";

import template from './creative-tag.html';
import {AdTagReplacers} from "../ad-unit/ad-tag-replacers";
import {CreativeDefaults} from "./creative-defaults";
import {Uuid} from "../../components/util/uuid";
import JSZip from "jszip";
import * as moment from "moment/moment";

const MODULE_NAME = "creative-tag-component";

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .component('adnCreativeTagComponent', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      type: '@',
      cacheSelect: '<',
      downloadOption: '<'
    },
    controller: function(LocalNetworkProfile, AdUnit, searchResource, $state, $document, $q, $window, dataViewResource) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.showDownloads = false;
        ctrl.targetId = "adn-" + Uuid.generate().substring(0, 6);
        ctrl.useCookies = true;
        ctrl.type = ctrl.type || 'CREATIVE';
        ctrl.clickTrackingUrl = '';
        ctrl.targeting = ctrl.type === 'ORDER' || ctrl.type === 'LINE_ITEM' || ctrl.type === 'CREATIVE_SET';
        ctrl.showOptions = ['STANDARD_JAVASCRIPT', 'GOOGLE_AD_MANAGER', 'GOOGLE_CAMPAIGN_MANAGER', 'DV360', 'ADFORM', 'XANDR'];
        ctrl.showOption = 'STANDARD_JAVASCRIPT';

        function whereTheActionHappens(data) {
          ctrl.optionCode = {};
          _.forEach(ctrl.showOptions, function(opt) {
            ctrl.optionCode[opt] = {};
          });

          const parentId = _.get(data, ['lineItemInfo', 'id']) || _.get(data, ['lineItem', 'id']) || _.get(data, ['creativeSet', 'id']) || _.get(data, ['model', 'id']);
          ctrl.lineItemName = _.get(data, ['lineItemInfo', 'name']) || _.get(data, ['lineItem', 'name']) || _.get(data, ['creativeSet', 'name']);
          const creative = (ctrl.type !== 'ORDER' && ctrl.type !== 'LINE_ITEM' && ctrl.type !== 'CREATIVE_SET' ? _.get(data, ['model']) : null);
          const parentType = _.get(ctrl.model, ['creativeSetParent']) || ctrl.type === 'CREATIVE_SET' ? "creativeSetId" : ctrl.type === 'ORDER' ? 'orderId' : "lineItemId";
          let adTagId = "";

          ctrl.dimensions = _.isArray(ctrl.dimensions) ? ctrl.dimensions : [[]];
          ctrl.dimensions[0] = _.isArray(ctrl.dimensions[0]) ? ctrl.dimensions[0] : [];

          ctrl.removeDimensions = function(index) {
            _.pullAt(ctrl.dimensions, index);
            ctrl.eventHook.removeControlHook('dims' + index + '.width');
            ctrl.eventHook.removeControlHook('dims' + index + '.height');
          };
          ctrl.addDimensions = function() {
            ctrl.dimensions = ctrl.dimensions || [];
            ctrl.dimensions.push([]);
          };
          ctrl.eventHook = {};

          function generateCode(codeFormat, variation, targetSuffix, pCreative) {
            const theTargetId = targetSuffix ? ctrl.targetId + "-" + targetSuffix : ctrl.targetId;
            const auW = _.get(pCreative, ['width']) || ctrl.auW;
            const auH = _.get(pCreative, ['height']) || ctrl.auH;
            const codeCreative = pCreative || creative;
            if (codeFormat === 'STANDARD_JAVASCRIPT') {
              if (variation === 2) {
                return CreativeDefaults.getScriptServe(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.dimensions, ctrl.clickTrackingUrl, ctrl.targeting);
              } else if (variation === 3) {
                return CreativeDefaults.getThirdParty(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.dimensions, auW, auH, ctrl.clickTrackingUrl, ctrl.targeting);
              }
              return CreativeDefaults.getJs(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, theTargetId, ctrl.dimensions, auW, auH, ctrl.clickTrackingUrl, ctrl.targeting);
            } else if (codeFormat === 'GOOGLE_AD_MANAGER') {
              if (variation === 2) {
                return CreativeDefaults.getScriptServeGAM(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.dimensions, ctrl.targeting);
              }
              return CreativeDefaults.getJsGAM(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, theTargetId, ctrl.dimensions, auW, auH, ctrl.targeting);
            } else if (codeFormat === 'DV360') {
              return CreativeDefaults.getJsGAM(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, theTargetId, ctrl.dimensions, auW, auH, ctrl.targeting);
            } else if (codeFormat === 'XANDR') {
              return CreativeDefaults.getXandr(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, theTargetId, ctrl.dimensions, auW, auH, ctrl.targeting);
            } else if (codeFormat === 'ADFORM') {
              return CreativeDefaults.getAdForm(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, theTargetId, ctrl.dimensions, auW, auH, ctrl.targeting);
            } else if (codeFormat === 'GOOGLE_CAMPAIGN_MANAGER') {
              return CreativeDefaults.getGoogleCampaignManager(parentType, parentId, codeCreative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, theTargetId, ctrl.dimensions, auW, auH, ctrl.targeting);
            }
          }

          ctrl.downloadAsCalculated = function(downloadFormat, codeFormat) {
            const creatives = data.creatives;
            ctrl.targeting = false;

            // Create a temporary anchor element
            const a = $document[0].createElement("a");
            let promise;

            let adTagCount = 1;
            if (downloadFormat === 'ZIPPED_TXT') {
              var zip = new JSZip();

              _.forEach(creatives, function(c) {
                zip.file(_.kebabCase(c.name) + ".txt", generateCode(codeFormat, null, adTagCount, c));
                adTagCount++;
              });
              promise = zip.generateAsync({type: 'blob'}).then(function(blob) {
                a.href = $window.URL.createObjectURL(blob);
                a.download = _.kebabCase(data.lineItem.name) + ".zip";
              });
            } else if (downloadFormat === 'TXT') {
              let outputString = "";
              _.forEach(creatives, function(c) {
                c.code = generateCode(codeFormat, null, adTagCount, c);
                outputString += c.code + "\n\n\n";
                adTagCount++;
              });

              const blob = new $window.Blob([outputString], { type: "text/plain" });
              a.href = $window.URL.createObjectURL(blob);
              a.download = _.kebabCase(data.lineItem.name) + ".txt";
              promise = $q.when();
            } else if (downloadFormat === 'XLS') {

              if (codeFormat === 'GOOGLE_CAMPAIGN_MANAGER') {
                const defaultHeaderValue = {value: '', fontWeight: 'bold', align: 'center', color: '#ffffff', backgroundColor: '#0d5d9e', width: 30, height: 25, alignVertical: 'center'};
                const summaryHeading = [
                  [
                    _.assign({}, defaultHeaderValue, {value: 'Contract Information', span: 2})
                  ]
                ];
                const summaryData = [
                  [{value: 'Advertiser ID'}, {value: data.advertiser.id}],
                  [{value: 'Advertiser Name'}, {value: data.advertiser.name}],
                  [{value: 'Campaign ID'}, {value: data.order.name}],
                  [{value: 'Campaign Name'}, {value: data.order.id}]
                ];

                const blankRow = [{value: ''}];

                const adFormSubHeading = [
                  [
                    _.assign({}, defaultHeaderValue, {value: 'Advertiser ID'}),
                    _.assign({}, defaultHeaderValue, {value: 'Advertiser Name'}),
                    _.assign({}, defaultHeaderValue, {value: 'Campaign ID'}),
                    _.assign({}, defaultHeaderValue, {value: 'Campaign Name'}),
                    _.assign({}, defaultHeaderValue, {value: 'Placement ID'}),
                    _.assign({}, defaultHeaderValue, {value: 'Placement External ID'}),
                    _.assign({}, defaultHeaderValue, {value: 'Site'}),
                    _.assign({}, defaultHeaderValue, {value: 'Placement Name'}),
                    _.assign({}, defaultHeaderValue, {value: 'Placement Compatibility'}),
                    _.assign({}, defaultHeaderValue, {value: 'Dimensions'}),
                    _.assign({}, defaultHeaderValue, {value: 'Start Date'}),
                    _.assign({}, defaultHeaderValue, {value: 'End Date'}),
                    _.assign({}, defaultHeaderValue, {value: 'Standard Tag'}),
                    _.assign({}, defaultHeaderValue, {value: 'Iframes/JavaScript Tag'}),
                    _.assign({}, defaultHeaderValue, {value: 'Internal Redirect Tag'}),
                    _.assign({}, defaultHeaderValue, {value: 'JavaScript Tag'})
                  ]
                ];

                const allData = [];
                _.forEach(creatives, function(c) {
                  const code = generateCode(codeFormat, null, adTagCount, c);
                  adTagCount++;

                  const creativeData = [];
                  creativeData.push({value: data.advertiser.id, alignVertical: 'top'});
                  creativeData.push({value: data.advertiser.name, alignVertical: 'top'});
                  creativeData.push({value: data.order.id, alignVertical: 'top'});
                  creativeData.push({value: data.order.name, alignVertical: 'top'});
                  creativeData.push({value: data.lineItem.id, alignVertical: 'top'});
                  creativeData.push({value: '', alignVertical: 'top'});
                  creativeData.push({value: '', alignVertical: 'top'});
                  creativeData.push({value: data.lineItem.name, alignVertical: 'top'});
                  creativeData.push({value: 'Display', alignVertical: 'top'});
                  creativeData.push({value: c.width + "x" + c.height, alignVertical: 'top'});
                  creativeData.push({value: moment(data.lineItem.startDate).format("YYYY-MM-DD HH:mm:ss"), alignVertical: 'top'});
                  creativeData.push({value: moment(data.lineItem.endDate).format("YYYY-MM-DD HH:mm:ss"), alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: code, alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: code, alignVertical: 'top'});
                  allData.push(creativeData);
                });
                writeXlsxFile([...summaryHeading, ...summaryData, blankRow, ...adFormSubHeading, ...allData], {
                  fileName: 'fred.xls',
                  columns: [{width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 5}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}]
                });
                return;
              }

              if (codeFormat === 'ADFORM') {
                const defaultHeaderValue = {value: '', fontWeight: 'bold', align: 'center', color: '#ffffff', backgroundColor: '#0d5d9e', width: 30, height: 25, alignVertical: 'center'};
                const summaryHeading = [
                  [
                    _.assign({}, defaultHeaderValue, {value: 'Summary', span: 2})
                  ]
                ];
                const summaryData = [
                  [{value: 'Export date'}, {value: moment().format("YYYY-MM-DD")}],
                  [{value: 'Advertiser name'}, {value: data.advertiser.name}],
                  [{value: 'Campaign name'}, {value: data.order.name}],
                  [{value: 'Campaign ID'}, {value: data.order.id}]
                ];

                const blankRow = [{value: ''}];

                const adFormHeading = [
                  [
                    _.assign({}, defaultHeaderValue, {value: 'Line item details', span: 5}),
                    null, null, null, null,
                    {value: ''},
                    _.assign({}, defaultHeaderValue, {value: 'Tag details', span: 17}),
                    null, null, null, null, null, null, null, null, null, null, null, null, null, null
                  ]
                ];

                const adFormSubHeading = [
                  [
                    _.assign({}, defaultHeaderValue, {value: 'Media'}),
                    _.assign({}, defaultHeaderValue, {value: 'Line item name'}),
                    _.assign({}, defaultHeaderValue, {value: 'Line item ID'}),
                    _.assign({}, defaultHeaderValue, {value: 'Start date'}),
                    _.assign({}, defaultHeaderValue, {value: 'End date'}),
                    {value: ''},
                    _.assign({}, defaultHeaderValue, {value: 'Type'}),
                    _.assign({}, defaultHeaderValue, {value: 'Tag ID'}),
                    _.assign({}, defaultHeaderValue, {value: 'Tag name'}),
                    _.assign({}, defaultHeaderValue, {value: 'Ad type'}),
                    _.assign({}, defaultHeaderValue, {value: 'Dimensions'}),
                    _.assign({}, defaultHeaderValue, {value: 'Script'}),
                    _.assign({}, defaultHeaderValue, {value: 'Additional information'}),
                    _.assign({}, defaultHeaderValue, {value: 'Target URL (click tracker)'}),
                    _.assign({}, defaultHeaderValue, {value: '1x1 pixel counter (impression tracker)'}),
                    _.assign({}, defaultHeaderValue, {value: 'Format'}),
                    _.assign({}, defaultHeaderValue, {value: 'Destination URL'}),
                    _.assign({}, defaultHeaderValue, {value: 'Append URL'}),
                    _.assign({}, defaultHeaderValue, {value: 'Replace URL'}),
                    _.assign({}, defaultHeaderValue, {value: 'Impression tracking pixel'}),
                    _.assign({}, defaultHeaderValue, {value: 'Click tracking pixel'}),
                    _.assign({}, defaultHeaderValue, {value: 'Ad name'}),
                    _.assign({}, defaultHeaderValue, {value: 'Preview'})
                  ]
                ];

                const allData = [];
                _.forEach(creatives, function(c) {
                  const code = generateCode(codeFormat, null, adTagCount, c);
                  const targetId = ctrl.targetId + "-" + adTagCount;
                  const previewUrl = $state.href('nude.preview', {creativeId: c.id, networkId: LocalNetworkProfile.getNetworkTag(), creativeWidth: c.width, creativeHeight: c.height}, {absolute: true});
                  adTagCount++;

                  const creativeData = [];
                  creativeData.push({value: data.advertiser.advertiserUrl, alignVertical: 'top'});
                  creativeData.push({value: data.lineItem.name, alignVertical: 'top'});
                  creativeData.push({value: data.lineItem.id, alignVertical: 'top'});
                  creativeData.push({value: moment(data.lineItem.startDate).format("YYYY-MM-DD HH:mm:ss"), alignVertical: 'top'});
                  creativeData.push({value: moment(data.lineItem.endDate).format("YYYY-MM-DD HH:mm:ss"), alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: "Direct", alignVertical: 'top'});
                  creativeData.push({value: targetId, alignVertical: 'top'});
                  creativeData.push({value: _.kebabCase(c.name + " " + c.width) + "x" + c.height, alignVertical: 'top'});
                  creativeData.push({value: _.startCase(_.lowerCase(_.get(c, ['layout', 'detectedCategory']))), alignVertical: 'top'});
                  creativeData.push({value: c.width + "x" + c.height, alignVertical: 'top'});
                  creativeData.push({value: code, alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: CreativeDefaults.getClickTracker(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies), alignVertical: 'top'});
                  creativeData.push({value: CreativeDefaults.getImpTracker(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies), alignVertical: 'top'});
                  creativeData.push({value: "javascriptDefault", alignVertical: 'top'});
                  creativeData.push({value: c.url, alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: "", alignVertical: 'top'});
                  creativeData.push({value: c.name, alignVertical: 'top'});
                  creativeData.push({value: previewUrl, alignVertical: 'top'});
                  allData.push(creativeData);
                });
                writeXlsxFile([...summaryHeading, ...summaryData, blankRow, ...adFormHeading, ...adFormSubHeading, ...allData], {
                  fileName: _.kebabCase(data.lineItem.name) + '.xls',
                  columns: [{width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 5}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}]
                });
                return;
              }

            }

            promise.then(function() {
              // Append to body and trigger click event
              $document[0].body.appendChild(a);
              a.click();

              // Cleanup
              $document[0].body.removeChild(a);
              $window.URL.revokeObjectURL(a.href);
            });
          };

          ctrl.downloadAs = function(code, title) {
            // Create a Blob with the text content
            const blob = new $window.Blob([code], { type: "text/plain" });

            // Create a temporary anchor element
            const a = $document[0].createElement("a");
            a.href = $window.URL.createObjectURL(blob);

            a.download = _.lowerCase(title).replaceAll(" ", "-") + ".txt";

            // Append to body and trigger click event
            $document[0].body.appendChild(a);
            a.click();

            // Cleanup
            $document[0].body.removeChild(a);
            $window.URL.revokeObjectURL(a.href);
          };

          ctrl.updateThirdPartyCode = function() {
            ctrl.emailCode = null;
            ctrl.networkTagId = LocalNetworkProfile.get('tagId');
            const whitelabelDomain = AdTagReplacers.getNetworkWhitelabelDomain(LocalNetworkProfile.getNetworkId());

            function updateCode() {
              ctrl.clickTracker = CreativeDefaults.getClickTracker(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies);
              ctrl.impTracker = CreativeDefaults.getImpTracker(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies);
              ctrl.optionCode.STANDARD_JAVASCRIPT.code = generateCode('STANDARD_JAVASCRIPT');
              ctrl.optionCode.STANDARD_JAVASCRIPT.code2 = generateCode('STANDARD_JAVASCRIPT', 2);
              ctrl.optionCode.STANDARD_JAVASCRIPT.code2Title = "Direct Script version of Default Adnuntius Tag";
              ctrl.optionCode.STANDARD_JAVASCRIPT.code3 = generateCode('STANDARD_JAVASCRIPT', 3);
              ctrl.optionCode.STANDARD_JAVASCRIPT.code3Title = "Iframe version of Default Adnuntius Tag";
              ctrl.optionCode.GOOGLE_AD_MANAGER.code = generateCode('GOOGLE_AD_MANAGER');
              ctrl.optionCode.GOOGLE_AD_MANAGER.code2 = generateCode('GOOGLE_AD_MANAGER', 2);
              ctrl.optionCode.GOOGLE_AD_MANAGER.code2Title = "Direct Script Version of Tag for Google Ad Manager";
              ctrl.optionCode.GOOGLE_AD_MANAGER.limited = true;
              ctrl.optionCode.DV360.code = generateCode('DV360');
              ctrl.optionCode.DV360.limited = true;
              ctrl.optionCode.XANDR.code = generateCode('XANDR');
              ctrl.optionCode.ADFORM.code = generateCode('ADFORM');
              ctrl.optionCode.ADFORM.xls = true;
              ctrl.optionCode.GOOGLE_CAMPAIGN_MANAGER.code = generateCode('GOOGLE_CAMPAIGN_MANAGER');
              ctrl.optionCode.GOOGLE_CAMPAIGN_MANAGER.limited = true;
              ctrl.optionCode.GOOGLE_CAMPAIGN_MANAGER.xls = true;
              const destUrl = _.find(_.get(creative, ['cToUrls'], []), function(v) {
                return !!v;
              }) || "";
              ctrl.emailCode = CreativeDefaults.getEmail(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), destUrl, ctrl.useCookies);
              if (whitelabelDomain) {
                ctrl.clickTracker = AdTagReplacers.replace(ctrl.clickTracker, whitelabelDomain, ctrl.networkTagId);
                ctrl.impTracker = AdTagReplacers.replace(ctrl.impTracker, whitelabelDomain, ctrl.networkTagId);
                ctrl.scriptServeCode = AdTagReplacers.replace(ctrl.scriptServeCode, whitelabelDomain, ctrl.networkTagId);
                ctrl.scriptServeCodeGAM = AdTagReplacers.replace(ctrl.scriptServeCodeGAM, whitelabelDomain, ctrl.networkTagId);
                ctrl.emailCode = AdTagReplacers.replace(ctrl.emailCode, whitelabelDomain, ctrl.networkTagId);
              }
            }

            if (ctrl.adUnit) {
              AdUnit.get({id: ctrl.adUnit.id}, function(au) {
                adTagId = _.get(au, "tagId");
                updateCode();
              });
            } else {
              updateCode();
            }
          };
          ctrl.updateThirdPartyCode();

          ctrl.searchAdUnits = function(searchElement) {
            searchResource.query({"q": searchElement.search, "types": "AdUnit"}).then(function(data) {
              ctrl.adUnitResults = data.searchResults;
            });
          };
        }

        if (ctrl.cacheSelect) {
          dataViewResource.getFromCache(_.get(ctrl.model, ['id']) || _.get(ctrl.model, ['model', 'id']) || _.get(ctrl.model, ['lineItem', 'id'])).then(function(data) {
            whereTheActionHappens(data);
          });
        } else {
          whereTheActionHappens(ctrl.model);
        }
      };
    }
  });

export default MODULE_NAME;