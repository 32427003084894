/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardFormModule from '../common/standard-form-module';
import integerValidator from '../common/directives/form-controls/integer-validation';
import {adnFormHelper} from "../common/controller/form-helper";
import _ from "lodash";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {getCountryName} from "../common/directives/country-select/countries";

const MODULE_NAME = 'earnings-account-controller';

angular.module(MODULE_NAME, [standardFormModule, integerValidator])

  .controller('EarningsAccountCtrl', function(model, modelComms, localUserProfile, Ruleset, LocalNetworkProfile, Blocklist, LocalUserPermissions, Team) {
    const ctrl = this;

    // need to initialise before onInit to make the country select component work as expected.
    model.address = model.address || {country: ""};

    ctrl.isAxPublisher = LocalUserPermissions.isAnyAxPublisher();
    ctrl.pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();

    const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
    ctrl.showFullPageOptions = ctrl.pureMarketplacePlatform && isSpecialUser;

    ctrl.showCountries = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1;

    const defaultCurrency = LocalNetworkProfile.get('defaultCurrency');
    ctrl.networkCurrencies = _.orderBy(LocalNetworkProfile.get('availableCurrencies'), function(curr) {
      return curr === defaultCurrency ? 0 : 1;
    });

    function cleanseDataToArray(sizeString) {
      const theData = (sizeString || "").split("x");
      const firstData = parseInt(_.get(theData, [0], -1), 10);
      const secondData = parseInt(_.get(theData, [1], -1), 10);
      return _.isFinite(firstData) && firstData > 0 && _.isFinite(secondData) && secondData > 0 ? [firstData, secondData] : [undefined, undefined];
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        ctrl.model.filterTeamsType = ctrl.model.filterTeamsType || 'NONE';
        ctrl.model.countriesExpanded = _.map(ctrl.model.countries || [], function(country) {
          return getCountryName(country);
        }).join(", ");
      },
      afterInitAfterSave: function() {
        ctrl.desktopWidth = "";
        ctrl.desktopHeight = "";
        ctrl.mobileWidth = "";
        ctrl.mobileHeight = "";

        const desktopArray = cleanseDataToArray(_.get(ctrl.model, ['fullPageSizes', 'DESKTOP']));
        ctrl.desktopWidth = desktopArray[0];
        ctrl.desktopHeight = desktopArray[1];

        const mobileArray = cleanseDataToArray(_.get(ctrl.model, ['fullPageSizes', 'MOBILE']));
        ctrl.mobileWidth = mobileArray[0];
        ctrl.mobileHeight = mobileArray[1];
      }
    });

    Blocklist.query({minimal: true}, function(page) {
      ctrl.blocklists = page.results;

      if (ctrl.model.blocklist && ctrl.model.blocklist.id) {
        ctrl.model.blocklist = _.find(ctrl.blocklists, ['id', ctrl.model.blocklist.id]);
      }
    });

    if (ctrl.pureMarketplacePlatform) {
      Ruleset.query({minimal: false}, function(page) {
        ctrl.siteRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'INVENTORY';
        });
        ctrl.targetingRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'TARGETING';
        });
        ctrl.sizeRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'SIZE';
        });
        if (ctrl.model.siteRuleset && ctrl.model.siteRuleset.id) {
          ctrl.model.siteRuleset = _.find(page.results, ['id', ctrl.model.siteRuleset.id]);
        }
        ctrl.rulesets = page.results;
      });
    }

    Team.query({where: 'type in STANDARD,AX_PUBLISHER'}, function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      ctrl.teams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
      });
      if (ctrl.isAxPublisher && allTeams.length > 0 && ctrl.teams.length > 0 && !ctrl.model.team) {
        ctrl.model.team = ctrl.teams[0];
      }
      if (_.get(ctrl, ['model', 'team', 'id'])) {
        const teamFound = _.find(ctrl.teams, function(t) {
          return t.id === ctrl.model.team.id;
        });
        if (!teamFound) {
          // this happens because of remnant sites that have a bad team assigned to an earnings account
          ctrl.teams.push(ctrl.model.team);
        }
      }
    });

    ctrl.onBeforeSubmit = function() {
      if (_.isFinite(ctrl.desktopWidth) && ctrl.desktopWidth > 0 && _.isFinite(ctrl.desktopHeight) && ctrl.desktopHeight > 0) {
        ctrl.model.fullPageSizes.DESKTOP = ctrl.desktopWidth + "x" + ctrl.desktopHeight;
      } else if (_.get(ctrl, ['model', 'fullPageSizes', 'DESKTOP'])) {
        delete ctrl.model.fullPageSizes.DESKTOP;
      }
      if (_.isFinite(ctrl.mobileWidth) && ctrl.mobileWidth > 0 && _.isFinite(ctrl.mobileHeight) && ctrl.mobileHeight > 0) {
        ctrl.model.fullPageSizes.MOBILE = ctrl.mobileWidth + "x" + ctrl.mobileHeight;
      } else if (_.get(ctrl, ['model', 'fullPageSizes', 'MOBILE'])) {
        delete ctrl.model.fullPageSizes.MOBILE;
      }
    };
  });

export default MODULE_NAME;
