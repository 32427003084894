import {Inject} from "@angular/core";
import {SearchHelper} from "./search-helper";

export class RecentArticlesSearchResource extends SearchHelper {
  constructor(@Inject('$http') $http, @Inject('localUserProfile') localUserProfile) {
    super($http, localUserProfile, 'targetingquery/recentarticles');
  }

  query(a1): any {
    this.updateEndPoint('targetingquery/recentarticles');
    return super.query(a1);
  }

  viewed(a1): any {
    this.updateEndPoint('targetingquery/viewedarticles');
    return super.query(a1);
  }
}
