/*
 * Copyright © 2025 Adnuntius AS.
 */

import {Inject} from "@angular/core";
import {ApiConfig} from "../../api";

export class CrawlerResource {
  private readonly url = ApiConfig.obtain().getVersionedUri('crawler');

  constructor(@Inject('$http') private $http: any) {
  }

  refresh(url: string) {
    return this.$http.get(this.url + "/refresh", {params: {url: url}}).then(function (response) {
      return response.data;
    });
  }

  testConfiguration(testUrl: string, crawlerConfiguration: any) {
    return this.$http.post(this.url, crawlerConfiguration, {params: {url: testUrl}}).then(function (response) {
      return response.data;
    });
  }

  testContextService(testUrl: string) {
    return this.$http.get(this.url, {params: {url: testUrl}}).then(function (response) {
      return response.data;
    });
  }
}
