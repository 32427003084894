/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from '../line-item/line-item-edit-2.html';
import listTemplate from './ax-line-item-list.html';

import standardModule from '../common/standard-module';
import targetingModule from '../targeting/targeting-module';
import lineItemController from '../line-item/line-item-controller';
import lineItemActionsController from '../line-item/line-item-actions-controller';

const MODULE_NAME = 'ax-line-item-module';

angular.module(MODULE_NAME, [standardModule, lineItemController, lineItemActionsController, targetingModule])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('ax-line-items.ax-line-item', 'LineItem', 'app.adv', 'app', listTemplate);

    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        listConfig: function() {
          return {moreParams: {excludeIfMissing: 'axProductId'}};
        }
      },
      views: {
        'context@app': {
          template: null,
          controller: null
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          lineItemView: /*@ngInject*/ function($q, $stateParams, dataViewResource) {
            if (!$stateParams.isNew) {
              return dataViewResource.get('LineItemView', {id: $stateParams.id}, false);
            }
          },
          model: /*@ngInject*/ function($stateParams, LineItem) {
            return LineItem.get({id: $stateParams.id}).$promise;
          }
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'CampaignCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: "",
            controllerAs: 'ctrl',
            controller: 'CampaignActionsCtrl'
          }
        }
      }, 'LineItem', [{type: 'Advertiser', func: 'canBeShownAdvertiser'}, {type: 'Order', func: 'isNotSelfService'}]));
  });

export default MODULE_NAME;