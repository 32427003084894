/**
 * Copyright © 2025 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";
import * as _ from 'lodash';

export class DataViewResource {
  private readonly uri: string = ApiConfig.obtain().getVersionedUri('dataview');

  currencies: any;
  dataViews: any;
  currentCalls: any;

  constructor(@Inject('$http') private $http: any, @Inject('$q') private $q: any, @Inject('$log') private $log: any) {
  }

  query() {
    // not really useful from a UI perspective
    return this.$http.get(this.uri).then(_.iteratee('data')).catch((response) => {
      this.$log.warn("DataView.query: " + response);
    });
  }

  getView(options, viewModel, parameters) {
    if (parameters && !_.isPlainObject(parameters)) {
      parameters = {id: parameters};
    }
    return this.$http.post(this.uri, [{
        viewModel: viewModel,
        parameters: parameters || {}
      }],
      {params: options}
    ).then((response) => {
      if (!(response.data.results && response.data.results.length === 1)) {
        return this.$q.reject('Expected only one data-view result');
      }
      const result: any = _.head(response.data.results);
      if (result.viewModel !== viewModel) {
        return this.$q.reject('Expected viewModel ' + viewModel + ' but got ' + result.viewModel);
      }
      return result.dataView;
    }).catch((response) => {
      this.$log.warn("DataView.getView", response);
      return this.$q.reject('Error returned from DataView');
    });
  }

  getFromCache(id) {
    if (this.currentCalls && this.currentCalls[id]) {
      return this.currentCalls[id];
    }
    const liIndex = _.findIndex(this.dataViews, (dv) => {
      return _.get(dv, ['lineItem', 'id']) === id;
    });
    return liIndex > -1 ? this.$q.when(this.dataViews[liIndex]) : this.get('LineItemView', {id: id}, false);
  }

  get(viewModel, parameters, includeStats) {
    if (parameters && !_.isPlainObject(parameters)) {
      parameters = {id: parameters};
    }
    this.currentCalls = this.currentCalls || {};
    const viewModelPromise = this.$http.post(this.uri, [{
        viewModel: viewModel,
        parameters: parameters || {}
      }],
      {params: {'includeStats': includeStats}}).then((response) => {
      if (_.get(response, ['data', 'results', 'length']) !== 1) {
        return this.$q.reject('Expected only one data-view result');
      }
      const result: any = _.head(response.data.results);
      if (result.viewModel !== viewModel) {
        return this.$q.reject('Expected viewModel ' + viewModel + ' but got ' + result.viewModel);
      }
      const results = _.get(result, ['dataView', 'results']) || _.get(result, ['dataView']) || {};
      if (viewModel === 'LineItemView' && results.lineItem) {
        this.dataViews = _.filter(this.dataViews, function (dv) {
          return _.get(dv, ['lineItem', 'id']) !== results.lineItem.id;
        });
        let limitedResult = _.cloneDeep(results);
        limitedResult = _.pick(limitedResult, ['advertiser', 'lineItem', 'creatives', 'order']);
        if (limitedResult.lineItem && limitedResult.lineItem.id) {
          limitedResult.lineItem = _.pick(limitedResult.lineItem, ['id', 'name']);
        }
        if (limitedResult.advertiser && limitedResult.advertiser.id) {
          limitedResult.advertiser = _.pick(limitedResult.advertiser, ['id', 'name', 'advertiserUrl']);
        }
        if (limitedResult.order && limitedResult.order.id) {
          limitedResult.order = _.pick(limitedResult.order, ['id', 'name']);
        }

        limitedResult.creatives = _.map(limitedResult.creatives, function (c) {
          const creativeData = _.pick(c, ['id', 'name', 'width', 'height', 'layoutParameters', 'constraintsToUrls', 'layout.id', 'layout.name', 'layout.detectedCategory']);
          creativeData['url'] = _.map(creativeData['constraintsToUrls'] || [], function (v) {
            return v;
          })[0] || "";
          if (!creativeData['url'] && c['assetHtmlUrls']) {
            creativeData['url'] = _.map(c['assetHtmlUrls'] || [], function (kv) {
              return _.map(kv || [], function (v) {
                return v;
              })[0] || "";
            })[0] || "";
          }
          return creativeData;
        });
        this.dataViews.splice(0, this.dataViews.length - 50);
        this.dataViews.push(limitedResult);
      }
      return results;
    }).catch((response) => {
      this.$log.warn("DataView.get", response);
      return this.$q.reject('Error returned from DataView');
    }).finally(() => {
      this.currentCalls[parameters.id] = false;
    });

    if (viewModel === 'LineItemView' && parameters.id) {
      this.currentCalls[parameters.id] = viewModelPromise;
    }

    return viewModelPromise;
  }
}
